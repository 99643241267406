//
// IMPORTS
//

@import "reset";
@import "variables";


/**************/
/* BASE RULES */
/**************/


body {
	background: $veryLightGray;
	font-size: 18px;
	font-family: $sans-serif;
	color: $text-color;
}

hr {
	border: none;
	border-top: 4px dotted $darkGray;
	margin-top: 2em;
	margin-bottom: 2em;
	width: 25px;
}

.container {
	margin: 0 auto;
	max-width: 740px;
	padding: 0 10px;
	width: 100%;
	
	@include tablet {
		padding: 0 3em;
	}
}

h1, h2, h3, h4, h5, h6 {
	font-family: $sans-serif;
	font-weight: bold;
	line-height: 1.3;
	margin: 2em 0 1em;
	padding: 0;

	@include mobile {
		line-height: 1.4;
	}
}


h1 {
	font-size: 2.25em;
	
	a {
		color: inherit;
	}
}

h2 {
	font-size: 1.6em;
}

h3 {
	font-size: 1.2em;
}

h4 {
	font-size: 1em;
}

p {
	margin: 0;
	margin-top: 1em;
	line-height: 1.3em;
	font-family: $serif;
	
	&:first-of-type {
		margin-top: 0;
	}
}

a {
	color: $link-color;
	text-decoration: underline;
	cursor: pointer;
	
	&:hover, &:active {
		color: darken($link-color,20);
	}
}

ul, ol {
	margin: 15px 0;
	padding-left: 30px;
	font-family: $serif;
}

ul {
	list-style-type: disc;
}

ol {
	list-style-type: decimal;
}

ol ul, ul ol, ul ul, ol ol {
	margin: 0;
}

ul ul, ol ul {
	list-style-type: circle;
}

li {
	margin-bottom: 0.5em;
}

table {
	width: 100%;
	margin-top: 1em;
	margin-bottom: 1em;
	border: solid 1px $darkGray;
	
	tr {
		border-top: solid 1px $darkGray;
	}
	
	th {
		background: $lightGray;
		text-transform: uppercase;
	}
	
	td {
		font-size: 0.9em;
	}
	
	td, th {
		padding-top: 1em;
		padding-bottom: 1em;
		padding-left: 0.5em;
		text-align: left;
	}
}

dl {
	margin-top: 1em;
	margin-bottom: 1em;
	font-family: $serif;
	
	dt {
		margin-bottom: 0.5em;
	}
	
	dd {
		margin-bottom: 1em;
	}
}

em, i {
	font-style: italic;
}

strong, b {
	font-weight: bold;
}

img {
	max-width: 100%;
}

.date {
	font-style: italic;
	color: $gray;
}

// Specify the color of the selection
::-moz-selection {
	color: $black;
	background: $lightGray;
}
::selection {
	color: $black;
	background: $lightGray;
}

.clearfix:before,
.clearfix:after {
	content: " ";
	display: table;
}

.clearfix:after {
	clear: both;
}

blockquote {
	margin: 1.8em .8em;
	border-left: 2px solid $gray;
	padding: 0.1em 1em;
	color: $accentColor;
	font-size: 1.4em;
	font-style: italic;
	font-weight: bold;
}

/*********************/
/* LAYOUT / SECTIONS */
/*********************/

//
// .masthead
//


.masthead {
	padding-bottom: 4em;
	padding-top: 3em;
	padding-left: 0.5em;
	padding-right: 0.5em;
	text-align: center;
	background-color: $black;
	background-image: url($hero);
	background-size: cover;
	
	.baseurl-link {
		font-size: 0.8em;
		margin-left: 1em;
		
		a {
			color: rgba(255, 255, 255, 0.5);
			
			&:hover {
				color: rgba(255, 255, 255, 0.75);
			}
		}
	}
	
	img.avatar {
		border-radius: 100%;
	}
	
	&.small {
		padding-bottom: 1em;
		padding-top: 1em;
		padding-left: 1em;
		padding-right: 1em;
		margin-bottom: 4em;
		
		.site-info {
			text-align: left;
		}
		
		.site-name {
			font-size: 2em;
			margin: 0;
		}
		
		nav {
			text-align: left;
			font-family: $alternative-sans-serif;
			color: rgba(255, 255, 255, 0.75);
			
			a {
				color: inherit;
			}
			
			@include mobile {
				display: block;
				margin: 0 auto;
			}
		}
		
		img.avatar {
			width: 65px;
			height: 65px;
			float: left;
			margin-right: 20px;
		}
	}
}

.site-info {
	text-align: center;
	font-family: $alternative-sans-serif;
	
	@include mobile {
		display: block;
		margin: 0 auto;
	}
}

.site-name {
	margin: 0;
	margin-top: 0.5em;
	color: $darkGray;
	cursor: pointer;
	font-family: $alternative-sans-serif;
	font-weight: bold;
	font-size: 3em;
	line-height: 1.2;
	
	a {
		color: $white;
		text-decoration: none;
		cursor: pointer;
	
		&:hover{
			color: $navbar-hover-color;
		}
	}
}

.site-description {
	margin: 0;
	color: rgba(255, 255, 255, 0.75);
	font-size: 16px;

	@include mobile {
		margin: 3px 0;
	}
}

.quicklinks {
	padding-left: 0;
	padding-right: 0;
	padding-top: 1em;
	padding-bottom: 1em;
	font-size: 0.85em;
	background: #eae7e7;
	text-align: center;
	font-family: $alternative-sans-serif;
	font-weight: bold;
	
	nav {
		.divider {
			color: $gray;
		}
	}
}


//
// .main
//

.posts {
	margin-top: 2em;
}

.posts > .post {
	padding-bottom: 2em;
	padding-top: 2em;
	border-bottom: 1px solid $lightGray;
}

.posts > .post:last-child {
	padding-bottom: 1em;
	border-bottom: none;
}

.post {
	padding-left: 0.5em;
	padding-right: 0.5em;
	
	header {
		margin-bottom: 2em;
	}

	.comments {
		margin-top: 10px;
	}

	.read-more {
		text-transform: uppercase;
	}

	a {
		text-decoration: none;
		color: $link-color;
	
		&:hover {
			color: darken($link-color,20);
		}
	}
	
	.entry {
		margin-bottom: 1em;
		
		a {
			text-decoration: underline;
		}
	}
	
	.post-title {
		margin: 0;
		margin-bottom: 0.5em;
		
		a {
			color: $text-color;
			text-decoration: none;
			
			&:hover {
				color: $text-color;
				text-decoration: underline;
			}
		}
	}

	.meta {
		margin-top: 0;
		color: $darkGray;
		font-size: 0.7em;
		
		.reading-time {
			float: right;
			color: #b7b7b7;
		}
		
		time {
			font-weight: bold;
		}
		
		a {
			font-weight: bold;
		}
	}
}

.post-heading {
	a {
		color: lighten($link-color, 20);
		
		&:hover{
			color: lighten($link-color,30);
		}
	}

	.meta {
		a {
			text-decoration: none;
			color: $white;
			
			&:hover {
				text-decoration: underline;
			}
		}
	}
 }

.page {
	h1 {
		margin-bottom: 1em;
		border-bottom: solid 1px #909090;
		border-style: inset;
	}
}

.site-footer {
	margin-top: 4em;
	border-top: 1px solid #ddd;
	text-align: center;
	border-bottom: 1px solid #ddd;
	background-color: $lightGray;
}

.archive-link {
	padding-left: 1em;
	padding-right: 1em;
	text-align: center;
	font-style: italic;
	margin-top: 3em;
	font-family: "Open Sans";
}


//
// archive
//

h1.archive {
	margin: 0;
}

h2.archive {
	font-size: 1em;
	margin-top: 0;
	font-family: "Roboto";
	
	a {
		color: $text-color;
		text-decoration: none;
		
		&:hover {
			color: $link-color;
			text-decoration: underline;
		}
	}
}

h3.archive {
	margin-top: 0;
	
	a {
		color: $text-color;
	}
}

article.archive {
	padding: 0 !important;
}

article.portfolio {
	header {
		min-height: 230px;
		
		img {
			width: 200px;
			height: auto;
			float: left;
			margin-right: 2em;
			margin-bottom: 2em;
		}
		
		h1 {
			margin: 0;
			line-height: 1em;
		}
	}
	
	p {
		text-indent: 0;
		margin-bottom: 0.8em;
	}
}


//
// footer
//


.svg-icon {
	padding-top: 15px;
	padding-bottom: 15px;
	
	img {
		height: 2em;
	}
	
	a {
		text-decoration: none;
		color: $text-color;
		cursor: pointer;
	
		&:hover {
			color: $link-color;
		}
	}
}

@import "highlights";
