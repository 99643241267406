
//
// VARIABLES
//


// Colors

$black: #000;
$darkerGray: #222;
$darkGray: #333;
$gray: #666;
$lightGray: #eee;
$white: #fff;
$blue: #4183C4;
$veryLightGray: #f7f7fa;
$lightBlue: #deeaff;
$accentColor: #195b7d;

// Main theme colors 
// Some cool main theme colors(violet:#8476ad;blue:#5cacee;red:#ff7373,#ff6f69;green:#6acf64,#2ddbb3;orange:#ffa268)

// main theme color(header, links, footer icons, buttons, post-title)

$text-color: #000;
$link-color: $blue;
$navbar-hover-color: #eae7e7;


// Images
$avatar: "/images/avatar.jpg";
$hero: "/images/hero.jpg";


// Font stacks
$helvetica: Helvetica, Arial, sans-serif;
$helveticaNeue: "Helvetica Neue", Helvetica, Arial, sans-serif;
$raleway: "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif;
$georgia: Georgia, serif;

$serif: "Times New Roman", serif;
$sans-serif: $helveticaNeue, sans-serif;
$alternative-sans-serif: "Open Sans", sans-serif;

@mixin tablet {
	@media screen and (min-width: 641px) and (max-width: 991px) {
		@content;
	}
}

@mixin mobile {
	@media screen and (max-width: 640px) {
		@content;
	}
}
